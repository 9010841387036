<template>
  <div class="funds-take">
    <v-container>
      <v-row
        align="center"
        justify="center"
        class="mb-6 pb-2"
      >
        <v-col
          cols="12"
          sm="2"
          md="4"
          lg="4"
        >
          <h1
            class="text-center"
          >
            سحب رصيد
          </h1>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="3"
          md="3"
          lg="3"
        >
          <v-checkbox
            v-model="haram"
            label="شركة الهرم للحوالات المالية"
            color="primary"
            value="haram"
            hide-details
          />
          <v-checkbox
            v-model="haram"
            label="الدفع الالكتروني"
            color="primary"
            value="epayment"
            hide-details
          />
          <v-checkbox
            v-model="haram"
            label="وحدات"
            color="primary"
            value="balance"
            hide-details
          />
          <hr>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="2"
          md="3"
          lg="3"
        >
          <v-btn
            x-large
            type="submit"
            color="primary"
            :disabled="haram !== 'haram'"
            block
          >
            سحب
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      haram: null,
    }
  },
}
</script>
