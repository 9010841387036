<template>
  <v-container
    class="has-background-image fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
      dense
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
      >
        <v-col cols="12">
          <div class="text-center ma-3">
            <h1> إكمال تسجيل الحساب</h1>
          </div>
        </v-col>
        <v-card class="glassy-card">
          <v-card-text>
            <v-form
              :disabled="loading"
            >
              <v-text-field
                v-model="form.name"
                label="الاسم الثلاثي"
                prepend-inner-icon="mdi-account"
                :error-messages="errors.name"
                outlined
                required
              />

              <v-text-field
                v-model="form.phone"
                label="رقم الموبايل"
                type="tel"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"
                outlined
                required
              />

              <v-text-field
                v-model="form.city"
                :items="cities"
                :error-messages="errors.city"
                label="العنوان"
                outlined
                required
              />
              <v-text-field
                v-model="form.amount"

                :error-messages="errors.amount"
                label="المبلغ المراد سحبه"
                type="number"
                outlined
                required
              />
              <v-btn
                color="primary"
                type="submit"
                :loading="loading"
                x-large
                block
                dark
                :to="{ name: 'funds.take.confirm' }"
              >
                سحب
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoginPage',
  data () {
    return {
      form: {
        name: '',
        phone: '',
        city: '',
        amount: '',
      },
      loading: false,
      errors: {},
    }
  },

}
</script>
