<template>
  <div class="funds">
    <v-container class="fill-height align-content-space-between">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="2"
          md="4"
          lg="4"
        >
          <h2 class="text-h4 text-center">
            الرصيد {{ amount }}
          </h2>
        </v-col>
      </v-row>

      <v-row
        align="center"
        justify="center"
      >
        <v-col
          v-for="fund in funds"
          :key="fund.id"
          cols="12"
          sm="4"
          md="8"
          lg="10"
        >
          <v-card class="mb-5">
            <v-card-title>
              {{ fund.type }}
            </v-card-title>
            <v-card-text>
              data
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="4"
          md="8"
          lg="10"
        >
          <v-btn
            :to="{ name: 'charge-funds' }"
            x-large
            color="primary"
            block
          >
            تعبئة الرصيد
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          sm="4"
          md="8"
          lg="10"
        >
          <v-btn
            :to="{ name: 'funds.take.method' }"
            x-large
            color="primary"
            block
          >
            سحب الرصيد
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      amount: 0,
      funds: [],
      accountHeaders: [
        {
          text: 'الرصيد الكلي',
          value: '',
          sortable: false,
        },
        {
          text: 'الرصيد المعلق',
          value: '',
          sortable: false,
        },
        {
          text: 'الرصيد القابل للسحب',
          value: '',
          sortable: false,
        },
        {
          text: 'رصيد مطلوب سحبه',
          value: '',
          sortable: false,
        }, {
          text: 'رصيد مسجوب سابقا',
          value: '',
          sortable: false,
        }, {
          text: 'مسحوبات على شكل وحدات',
          value: '',
          sortable: false,
        }, {
          text: 'مسحوبات نقدية',
          value: '',
          sortable: false,
        }, {
          text: 'الدفع الالكتروني',
          value: '',
          sortable: false,
        },
      ],
    }
  },
  async created () {
    const res = await this.axios.get('/balance')
    this.amount = res.data.data.amount
  },
}
</script>
