<template>
  <v-container>
    <v-card>
      <v-card-title>تنفيذ المهمة</v-card-title>
      <v-card-text class="ps-0">
        <v-timeline
          align-top
          :dense="$vuetify.breakpoint.smAndDown"
        >
          <v-timeline-item icon="mdi-numeric-1">
            ادخل الصفحة
            <v-btn
              :href="task.page_url"
              target="_blank"
              text
            >
              اضغط هنا
            </v-btn>
          </v-timeline-item>
          <v-timeline-item icon="mdi-numeric-2">
            ادعُ أصدقائك
          </v-timeline-item>
          <v-timeline-item icon="mdi-numeric-3">
            <v-text-field
              v-model.number="execution.invitations_count"
              label="عدد الدعوات المرسلة"
              type="number"
              :error-messages="errors.invitations_count"
            />
          </v-timeline-item>
          <v-timeline-item icon="mdi-numeric-4">
            <v-file-input
              v-model="execution.screenshot"
              placeholder="خذ لقطة شاشة واضغط هنا"
              outlined
              accept="image/*"
              :error-messages="errors.screenshot"
            />
          </v-timeline-item>
          <v-timeline-item icon="mdi-numeric-5">
            <v-btn
              color="primary"
              :disabled="!execution.screenshot || execution.invitations_count <= 0"
              @click="submit"
            >
              إرسال
            </v-btn>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  data () {
    return {
      task: {},
      execution: {
        invitations_count: 0,
        screenshot: null,
        task_id: this.id,
      },
      errors: {},
    }
  },

  async created () {
    this.task = (await this.axios.get(`/available-tasks/${this.id}`)).data
  },

  methods: {
    async submit () {
      try {
        const data = new FormData()
        for (const key in this.execution) {
          data.append(key, this.execution[key])
        }

        await this.axios.post('/task-executions', data, { headers: { 'Content-Type': 'multipart/form-data' } })
        await this.$router.push({ name: 'start' })
      } catch (e) {
        if (e && e.response) {
          const { response } = e
          this.errors = response.data.errors
        }
      }
    },
  },
}
</script>
