<template>
  <div class="fill-height">
    <v-form
      class="fill-height"
      :disabled="loading"
      @submit.prevent="save"
    >
      <v-container class="fill-height align-content-space-between">
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="2"
            md="4"
            lg="4"
          >
            <h2
              class="text-h4 text-center"
            >
              إضافة مهمة
            </h2>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
          >
            <h3 class="text-right">
              نوع المهمة
            </h3>
            <v-select
              v-model="form.type"
              :items="types"
              :error-messages="errors['type']"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
          >
            <h3 class="text-right">
              اختر الصفحة
            </h3>
            <v-text-field
              v-model="form.page_url"
              placeholder="https://fb.com/RoduanKD"
              dir="ltr"
              :error-messages="errors['page_url']"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
          >
            <h3 class="text-right">
              عدد اللايكات
            </h3>
            <v-select
              v-model="form.required_likes_count"
              :items="required_likes_counts"
              :error-messages="errors['required_likes_count']"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
          >
            <h3 class="text-right">
              شريحة الدفع
            </h3>
            <v-select
              v-model="form.payment_chip"
              :items="payment_chips"
              :error-messages="errors['payment_chip']"
            />
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            sm="2"
            md="4"
            lg="4"
            class="flex-grow-0"
          >
            <v-btn
              x-large
              type="submit"
              color="primary"
              :loading="loading"
            >
              إرسال طلب بدء المهمة
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
export default {
  data: () => ({
    form: {
      type: 'مهمة دعوة الأصدقاء',
      page_url: '',
      required_likes_count: 3000,
      payment_chip: 50000,
    },

    types: ['مهمة دعوة الأصدقاء'],
    required_likes_counts: [3000],
    payment_chips: [50000],
    errors: {},
    loading: false,
  }),

  methods: {
    save () {
      this.loading = true
      this.axios.get('/sanctum/csrf-cookie').then(() => {
        this.axios.post('/user-tasks', this.form).then((res) => {
          console.log(res.data)
          this.$router.push({ name: 'tasks.create.task-sent' })
        }).catch((err) => {
          if (err.status === 422) {
            this.errors = err.data.errors
          }
          if (err.status === 403) {
            switch (err.data.message) {
              case 'insufficient_balance':
                this.$router.push({ name: 'no-funds' })
                break
            }
          }
        }).then(() => {
          this.loading = false
        })
      })
    },
  },
}
</script>
