<template>
  <div class="submit-issue">
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="4"
          md="4"
          lg="4"
        >
          <h1
            class="text-center mt-4 mb-6 pb-2"
          >
            الإبلاغ عن مشكلة
          </h1>
          <v-card>
            <v-card-text
              class=" text-h5
              text-center"
            >
              <v-textarea
                v-model="form.issue"
                :error-messages="errors.issue"
                label="اخبرنا عن مشكلتك لنقوم بحلها"
                aria-rowcount="4"
                outlined
                required
              />
              <v-btn
                x-large
                type="submit"
                color="primary"
                class="mt-9"
              >
                إبلاغ
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'LoginPage',
  data () {
    return {
      form: {
        issue: '',
      },
      loading: false,
      errors: {},
    }
  },

}
</script>
